<template>
	<div class="page-sub-box ewm">
		<div class="locker_setting_list sub_new_style01 sub_ui_box1">
			<div class="page_search_box line_bottom_1px">
				<DxValidationGroup ref="validationGroup">
					<div class="inner alL flex items-center">
						<div>생성일자</div>
						<div class="ui-datepicker-item">
							<DxDateBox
								styling-mode="outlined"
								width="140"
								v-model="searchType.customTypes.frRegDt"
								type="date"
								dateSerializationFormat="yyyyMMdd"
								display-format="yyyy.MM.dd"
								dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
								:max="searchType.customTypes.toRegDt"
								maxLength="10"
								invalid-date-message="입력 데이터가 유효하지 않습니다."
							>
								<DxValidator>
									<DxRequiredRule message="생성일자 기간은 필수입니다." />
								</DxValidator>
							</DxDateBox>
						</div>
						<div class="ui-datepicker period">
							<span class="dash">~</span>
						</div>
						<div class="ui-datepicker-item">
							<DxDateBox
								styling-mode="outlined"
								width="140"
								v-model="searchType.customTypes.toRegDt"
								type="date"
								dateSerializationFormat="yyyyMMdd"
								display-format="yyyy.MM.dd"
								dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
								maxLength="10"
								:min="searchType.customTypes.frRegDt"
								invalid-date-message="입력 데이터가 유효하지 않습니다."
							>
								<DxValidator>
									<DxRequiredRule message="생성일자 기간은 필수입니다." />
								</DxValidator>
							</DxDateBox>
						</div>

						<DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="selectDataList" />
					</div>
				</DxValidationGroup>
			</div>
		</div>

		<esp-dx-data-grid
			:data-grid="dataGrid"
			ref="educationSubjectGrid"
			@init-new-row="onInitNewRow"
			@cell-hover-changed="onCellHoverChanged"
			@saving="e => onSave(e)"
			@selection-changed="onSelectionChanged"
		>
		</esp-dx-data-grid>

		<DxPopover width="auto" :visible="defaultVisible" :target="hoverTarget" position="top">
			<div v-html="popover_description"></div>
		</DxPopover>

		<modal-add-agent
			:isOpen="modalAddAgent.visible"
			:showModalTitle="true"
			:selectedIdList="modalAddAgent.selectedIdList"
			saveBtnTxt="선택"
			title="강사 정보"
			@closeModal="onModalAddAgentClose"
			@saveModal="onModalAddAgentSave"
		/>
	</div>
</template>

<script>
import { DxButton } from 'devextreme-vue/button';
import { DxDateBox } from 'devextreme-vue/date-box';
import { DxFileUploader } from 'devextreme-vue/file-uploader';
import { DxPopover } from 'devextreme-vue/popover';
import { DxSwitch } from 'devextreme-vue/switch';
import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
import DxValidationGroup from 'devextreme-vue/validation-group';
import { getPastFromToday, isSuccess } from '@/plugins/common-lib';

import ModalAddAgent from '@/components/ewm/hr/modal-add-agent.vue';
import CustomStore from "devextreme/data/custom_store";
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid.vue";

let vm = this;

export default {
	components: {
    EspDxDataGrid,
		DxButton,
		DxDateBox,
		DxPopover,
		ModalAddAgent,
		DxRequiredRule,
		DxValidator,
		DxValidationGroup,
	},
	data() {
		return {
			codeMap: {},
			attachFileUrl: {
				fileUpload: 'COMMON_ATTACHED_FILE_UPLOAD',
				fileDownload: 'COMMON_ATTACHED_FILE_DOWNLOAD',
				fileDelete: 'COMMON_ATTACHED_FILE_DELETE',
				fileDeleteAll: 'COMMON_ATTACHED_FILE_GROUP_DELETE',
				fileCopy: 'COMMON_ATTACHED_FILE_COPY',
			},
			dataGridLoopup: {
				divisionCd: {
					dataSource: [],
					displayExpr: 'codeNm',
					valueExpr: 'codeId',
				},
				typeCd: {
					dataSource: [],
					displayExpr: 'codeNm',
					valueExpr: 'codeId',
				},
			},
			modalAddAgent: {
				visible: false,
				selectedIdList: [],
				target: null,
			},
			config: {
				pageSetting: {
					//pageData pageSetting 관련
					config: {},
				},
			},
			defaultVisible: false,
			popover_caption: '',
			popover_description: '',
			hoverTarget: '',
			teacherSelectionChanged: false,
			selectedRowKey: null,
			selectedRowIndex: null,
			stylingMode: 'outlined', //outlined, underlined, filled
			searchType: {
				obj: {
					eduNm: '',
					searchTexts: {
						width: '250',
					},
				},
				defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
				defaultValue: 'ROOT',
				customTypes: {
					frRegDt: getPastFromToday(11, 'months'),
					toRegDt: getPastFromToday(0, 'days'),
				},
				paramsData: {},
			},
			dataGrid: {
				keyExpr: 'subjId',
				refName: 'educationSubjectGrid',
				allowColumnResizing: true, //컬럼 사이즈 허용
				columnResizingMode: 'widget',
				columnAutoWidth: true,
				focusedRowEnabled: false,
				showBorders: false, //border 유무
				showColumnHeaders: true, //컬럼 헤더 유무
				showColumnLines: false, //컬럼 세로선 유무
				showRowLines: true, //컬럼 가로선 유무
				rowAlternationEnabled: false,
				dataSource: [],
				// width:'200',     // 주석처리시 100%
				height: 'calc(100vh - 310px)', // 주석처리시 100%
				scrolling: {
					mode: 'standard',
				},
				callApi: 'CALL_EWM_API',
				apiActionNm: {
					//select: 'EWM_EDUCATION_SUBJECT_LIST',
					//insert: "EWM_EDUCATION_SUBJECT_SAVE",
					//update: 'EWM_EDUCATION_SUBJECT_SAVE',
					//delete: 'EWM_EDUCATION_SUBJECT_DELETE',
				},
				parentIsCopy: false,
				selectedCopyData: null,
				customEvent: {
					//그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
					cellHoverChanged: true,
					cellPrepared: false,
					checkDataBeforeSaving: false,
					editingStart: true,
					initNewRow: true,
					rowInserted: false,
					rowInserting: false,
					saving: true,
					rowPrepared: true,
					rowUpdated: true,
				},
				showActionButtons: {
					copy: true,
					select: false,
					update: false,
					delete: false,
					excel: false,
					customButtons: [
						{
							widget: 'dxButton',
							options: {
								icon: '',
								text: '삭제',
								elementAttr: { class: 'btn_XS white light_filled trash' },
								width: 60,
								height: 30,
								onClick() {
									vm.onDeleteData();
								},
							},
							location: 'before',
						},
					],
				},
				grouping: {
					contextMenuEnabled: false,
					autoExpandAll: false,
					allowCollapsing: true,
					expandMode: 'rowClick', // rowClick or buttonClick
				},
				groupPanel: {
					visible: false,
				},
				columnChooser: {
					enabled: false, // 컬럼 Chooser 버튼 사용유무
				},
				loadPanel: {
					enabled: true, // 로딩바 표시 유무
				},
				sorting: {
					mode: 'multiple', // single multiple
				},
				remoteOperations: {
					// 서버사이드 여부
					filtering: false,
					sorting: false,
					grouping: false,
					paging: false,
				},
				paging: {
					enabled: false,
					pageSize: 10,
					pageIndex: 0, // 시작페이지
				},
				pager: {
					visible: false, //페이저 표시 여부
					showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
					allowedPageSizes: [],
					displayMode: 'compact', //표시 모드 : ['full', 'compact']
					showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
					showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
				},
				filterRow: {
					visible: true,
					operationDescriptions: {
						contains: '포함',
					},
				},
				headerFilter: {
					visible: true,
				},
				editing: {
					allowUpdating: options => {
						if (options.row.rowType == 'data' && options.row.data.useFl === this.$_enums.common.stringUsedFlag.YES.value) {
							return false;
						} else {
							return true;
						}
					}, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
					allowAdding: true, // 추가 버튼을 없애고 싶으면 false설정
					allowDeleting: false,
					mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
					startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
					selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
				},
				selecting: {
					mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
					selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
					showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
				},
				columns: [
					{
						caption: '강의명',
						dataField: 'eduNm',
						height: 40,
						visible: true,
						allowEditing: true,
						sortOrder: 'none', // acs desc none
						allowHeaderFiltering: false,
						allowGrouping: false,
						requiredRule: {
							message: '필수 항목입니다.',
						},
					},
					{
						caption: '진행방식',
						dataField: 'divisionCd',
						width: 180,
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: true,
						sortOrder: 'none',
						allowHeaderFiltering: true,
						allowGrouping: false,
						calculateSortValue: data => vm.calculateSortValue(data, 'divisionCd'),
						//calculateSortValue: data => this.$_enums.ewm.eduEducationProcessDivision.values.find(e => data.divisionCd == e.value).label,
						requiredRule: {
							message: '필수 항목입니다.',
						},
						lookup: {
							dataSource: this.$_enums.ewm.eduEducationProcessDivision.values,
							displayExpr: 'label',
							valueExpr: 'value',
						},
					},
					{
						caption: '강의시간',
						dataField: 'eduTime',
						dataType: 'number',
						width: 150,
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: true,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						allowGrouping: false,
						calculateDisplayCellValue: rowData => vm.calculateEduTime(rowData),
						//calculateCellValue: rowData => Number(rowData.eduTime),
						patternRule: {
							//pattern: '^(0|[1-9]+[0-9]*)$',
							pattern: '^[1-9]\\d*$',
							message: '분단위로 입력하세요.',
						},
						requiredRule: {
							message: '필수 항목입니다.',
						},
					},
					{
						caption: '강의유형',
						dataField: 'typeCd',
						width: 190,
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: true,
						sortOrder: 'none',
						allowHeaderFiltering: true,
						allowGrouping: false,
						calculateSortValue: data => vm.calculateSortValue(data, 'typeCd'),
						requiredRule: {
							message: '필수 항목입니다.',
						},
						lookup: {},
					},
					{
						caption: '강사',
						dataField: 'teachers',
						width: 170,
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: false,
						allowFiltering: false,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						allowGrouping: false,
						allowSorting: false,
						/* calculateDisplayCellValue: rowData => {
							if (rowData.teachers) {
								const teachers = rowData.teachers.filter(v => v.delFl == this.$_enums.common.stringUsedFlag.NO.value);

								if (teachers.length == 1) {
									return `${teachers[0].teacherName}[${teachers[0].teacherId}]`;
								} else if (teachers.length > 1) {
									return `${teachers.length}명`;
								} else {
									return '';
								}
							}
						}, */
						cellTemplate: (container, options) => {
							let aTag =
								options.data.useFl === this.$_enums.common.stringUsedFlag.YES.value
									? document.createElement('div')
									: document.createElement('a');

							if (options.data.teachers) {
								const teachers = options.data.teachers.filter(v => v.delFl == this.$_enums.common.stringUsedFlag.NO.value);

								if (teachers.length == 1) {
									aTag.innerText = `${teachers[0].teacherName}[${teachers[0].teacherId}]`;
								} else if (teachers.length > 1) {
									aTag.innerText = `${teachers.length}명`;
								} else {
									aTag.innerText = '선택';
								}
							}

							if (options.data.useFl !== this.$_enums.common.stringUsedFlag.YES.value) {
								aTag.addEventListener('click', () => {
									if (options.data.useFl === this.$_enums.common.stringUsedFlag.YES.value) {
										return;
									}

									vm.onAddTeachers(options);
								});
							}

							container.append(aTag);
						},
						/*editCellTemplate: (container, options) => {
							if (options.data.useFl === this.$_enums.common.stringUsedFlag.YES.value) {
								return;
							}

							let aTag = document.createElement('a');
							aTag.innerText = '선택';
							aTag.addEventListener('click', () => {
								vm.onAddTeachers(options);
							});

							container.append(aTag);
						},*/
						//cssClass: 'ewm-education-subject-cell-teacher',
						requiredRule: {
							message: '필수 항목입니다.',
						},
					},
					{
						caption: '자료',
						dataField: 'attacheFiles',
						visible: false,
					},
					{
						caption: '교육자료',
						dataField: 'fileGroupId',
						width: 130,
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: true,
						allowFiltering: false,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						allowGrouping: false,
						allowSorting: false,
						cellTemplate: (container, options) => {
							if (options.data.fileGroupId) {
								const divTag = document.createElement('div');
								divTag.className = 'inline-block';

								const downBtn = new DxButton({
									propsData: {
										icon: 'download',
										hint: '다운로드',
										onClick: e => {
											e.event.stopPropagation();
											vm.onDataDownload(options.data);
										},
									},
								});
								downBtn.$mount();
								divTag.appendChild(downBtn.$el);

								const fileInfoPopover = new DxPopover({
									propsData: {
										target: divTag,
										contentTemplate: `<div>${options.data.attacheFiles[0].originalFileFullName}</div>`,
										position: 'top',
										showEvent: 'mouseenter',
										hideEvent: 'mouseleave',
									},
								});
								fileInfoPopover.$mount();
								divTag.appendChild(fileInfoPopover.$el);

								container.append(divTag);

								/* let aTag = document.createElement('a');
								aTag.innerText = '교육자료';
								aTag.addEventListener('click', () => {
									vm.onDataDownload(options.data);
								});

								const fileInfoPopover = new DxPopover({
									propsData: {
										target: aTag,
										contentTemplate: `<div>${options.data.attacheFiles[0].originalFileFullName}</div>`,
										position: 'top',
										showEvent: 'mouseenter',
										hideEvent: 'mouseleave',
									},
								});
								fileInfoPopover.$mount();

								aTag.appendChild(fileInfoPopover.$el);

								container.append(aTag); */
							}
						},
						editCellTemplate: (container, options) => {
							const uploadBtn = new DxButton({
								propsData: {
									elementAttr: {
										class: `ewm-edu-subjt-file-upload-${options.row.key}`,
									},
									disabled: options.data.useFl === this.$_enums.common.stringUsedFlag.YES.value ? true : false,
									visible: options.data.useFl === this.$_enums.common.stringUsedFlag.YES.value ? false : true,
									icon: 'upload',
									hint: '업로드',
								},
							});
							uploadBtn.$mount();
							container.append(uploadBtn.$el);

							const fileUploader = new DxFileUploader({
								propsData: {
									accept: '*',
									dialogTrigger: `.ewm-edu-subjt-file-upload-${options.row.key}`,
									labelText: '',
									multiple: false,
									selectButtonText: '↑',
									disabled: options.data.useFl === this.$_enums.common.stringUsedFlag.YES.value ? true : false,
									hint: '업로드',
									//readOnly: true,
									showFileList: false,
									uploadHeaders: {
										Authorization: `Bearer ${this.$store.getters.getAccessToken}`,
									},
									name: 'file',
									uploadMethod: 'POST',
									uploadMode: 'instantly',
									uploadUrl: vm.getAttachFileUploadUrl(), //`http://ers-dev.ecstel.co.kr:8081/ecss/v1/file/group/file-upload`,
									visible: false,
									onUploaded: e => {
										const responseText = JSON.parse(e.request.responseText);
										const uploadedFiles = responseText.data;

										if (uploadedFiles.length > 0) {
											//const uploadedFileName = e.file.name;

											//options.data.attacheFiles = [{
											const attacheFilesCellValue = [
												{
													groupId: uploadedFiles[0].fileGroupId,
													fileName: uploadedFiles[0].fileName,
													extension: uploadedFiles[0].fileExtension,
													fileFullName: uploadedFiles[0].fileFullName,
													//originalName: uploadedFileName.slice(0, uploadedFileName.lastIndexOf('.')),
													originalName: uploadedFiles[0].originalFileName,
													originalFileFullName: uploadedFiles[0].originalFileFullName,
													fileSize: uploadedFiles[0].fileSize,
													sequence: uploadedFiles[0].fileSequence,
												},
											];

											options.component.cellValue(options.rowIndex, 'attacheFiles', attacheFilesCellValue);
											options.component.cellValue(options.rowIndex, 'fileGroupId', uploadedFiles[0].fileGroupId);
										}
									},
									onUploadError: e => {
										this.$_Toast('업로드 오류' + e);
									},
								},
							});

							fileUploader.$mount();
							container.append(fileUploader.$el);

							if (options.data.fileGroupId) {
								const divTag = document.createElement('div');
								divTag.className = 'inline-block';

								const removeBtn = new DxButton({
									propsData: {
										elementAttr: {
											class: 'ml-2',
										},
										disabled: options.data.useFl === this.$_enums.common.stringUsedFlag.YES.value ? true : false,
										visible: options.data.useFl === this.$_enums.common.stringUsedFlag.YES.value ? false : true,
										icon: 'remove',
										hint: '삭제',
										onClick: () => {
											if (!options.data.subjId) {
												vm.onDataRemove(options.data);
											}
											options.component.cellValue(options.rowIndex, 'fileGroupId', null);
											options.component.cellValue(options.rowIndex, 'attacheFiles', []);
										},
									},
								});
								removeBtn.$mount();
								divTag.appendChild(removeBtn.$el);

								const fileInfoPopover = new DxPopover({
									propsData: {
										target: divTag,
										contentTemplate: `<div>${options.data.attacheFiles[0].originalFileFullName}</div>`,
										position: 'top',
										showEvent: 'mouseenter',
										hideEvent: 'mouseleave',
									},
								});
								fileInfoPopover.$mount();
								divTag.appendChild(fileInfoPopover.$el);

								container.append(divTag);
							}
						},
					},
					{
						caption: '설명',
						dataField: 'description',
						height: 40,
						alignment: 'left',
						visible: true,
						allowEditing: true,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						allowGrouping: false,
					},
					{
						caption: '사용여부',
						dataField: 'viewFl',
						width: 130,
						height: 40,
						alignment: 'center', // left center right
						visible: true,
						allowEditing: false,
						sortOrder: 'none', // acs desc none
						allowHeaderFiltering: false,
						calculateSortValue: data => {
							return this.$_enums.common.stringUsedFlag.values.find(e => data.viewFl == e.value).label;
						},
						cellTemplate: (container, options) => {
							const switchBtn = new DxSwitch({
								propsData: {
									value: options.data.viewFl === this.$_enums.common.stringUsedFlag.YES.value,
									disabled: options.data.useFl === this.$_enums.common.stringUsedFlag.YES.value ? true : false,
									//readOnly: true,
									onValueChanged: e => {
										const viewFlVal = e.value
											? this.$_enums.common.stringUsedFlag.YES.value
											: this.$_enums.common.stringUsedFlag.NO.value;
										//options.setValue(viewFlVal);
										this.$refs.educationSubjectGrid.getGridInstance.cellValue(options.rowIndex, 'viewFl', viewFlVal);
									},
								},
							});
							switchBtn.$mount();
							container.append(switchBtn.$el);
						},
						/* editCellTemplate: (container, options) => {
							const switchBtn = new DxSwitch({
								propsData: {
									value: options.data.viewFl === this.$_enums.common.stringUsedFlag.YES.value,
									disabled: options.data.useFl === this.$_enums.common.stringUsedFlag.YES.value ? true : false,
									onValueChanged: e => {
										const viewFlVal = e.value
											? this.$_enums.common.stringUsedFlag.YES.value
											: this.$_enums.common.stringUsedFlag.NO.value;
										options.setValue(viewFlVal);
									},
								},
							});
							switchBtn.$mount();
							container.append(switchBtn.$el);
						}, */
						lookup: {
							dataSource: this.$_enums.common.stringUsedFlag.values,
							displayExpr: 'label',
							valueExpr: 'value',
						},
					},
					{
						caption: '교육이력',
						dataField: 'useFl',
						width: 130,
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: false,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						lookup: {
							dataSource: this.$_enums.common.stringUsedFlag.values,
							displayExpr: 'label',
							valueExpr: 'value',
						},
						calculateSortValue: data => {
							return this.$_enums.common.stringUsedFlag.values.find(e => data.useFl == e.value).label;
						},
					},
				],
			},
		};
	},
	computed: {
		validationGroup: function() {
			return this.$refs['validationGroup'].instance;
		},
	},
	methods: {
		calculateEduTime(rowData) {
			const pad = num => num.toString().padStart(2, '0');
			return `${pad(Math.floor(rowData.eduTime / 60))}:${pad(rowData.eduTime % 60)}`;
		},
		/** @description : 소트설정 */
		calculateSortValue(data, clsCd) {
			if (clsCd == 'divisionCd') {
				//return this.dataGridLoopup.divisionCd.dataSource.find(e => data.divisionCd == e.codeId).codeNm;
				return this.$_enums.ewm.eduEducationProcessDivision.values.find(e => data.divisionCd == e.value).label;
			} else if (clsCd == 'typeCd') {
				return this.dataGridLoopup.typeCd.dataSource.find(e => data.typeCd == e.codeId).codeNm;
				//return this.$_enums.ewm.eduEducationType.values.find(e => data.typeCd == e.value).label;
			}
		},
		/** @description : 인사팝업 열기 */
		onModalAddAgentOpen() {
			this.modalAddAgent.visible = true;
		},
		/** @description : 인사팝업 닫기 */
		onModalAddAgentClose() {
			this.modalAddAgent.visible = false;
			this.modalAddAgent.selectedIdList = [];
		},
		/** @description : 인사팝업 저장버튼 클릭 */
		onModalAddAgentSave(agtData) {
			this.onModalAddAgentClose();

			if (!agtData.length) {
				return;
			}

			let currentTeachers = this.$refs.educationSubjectGrid.getGridInstance.cellValue(this.selectedRowIndex, 'teachers');

			console.log('currentTeachers', currentTeachers);
			if (currentTeachers.length > 0) {
				currentTeachers.forEach(teacher => {
					if (agtData.findIndex(agent => agent.agtid == teacher.teacherId) < 0) {
						teacher.delFl = this.$_enums.common.stringUsedFlag.YES.value;
					} else {
						teacher.delFl = this.$_enums.common.stringUsedFlag.NO.value;
					}
				});

				currentTeachers = currentTeachers.filter(
					teacher => teacher.id || teacher.delFl != this.$_enums.common.stringUsedFlag.YES.value,
				);
			}

			const selectedTeachers = [];

			agtData.forEach(agent => {
				if (currentTeachers.length < 1 || currentTeachers.findIndex(teacher => agent.agtid == teacher.teacherId) < 0) {
					selectedTeachers.push({
						delFl: this.$_enums.common.stringUsedFlag.NO.value,
						teacherId: agent.agtid,
						teacherName: agent.agtNm,
					});
				}
			});

			this.$refs.educationSubjectGrid.getGridInstance.cellValue(this.selectedRowIndex, 'teachers', [
				...currentTeachers,
				...selectedTeachers,
			]);
		},
		onCellHoverChanged(e) {
			this.defaultVisible = false;
			this.hoverTarget = e.cellElement;
			if (e.rowType === 'data' && e.eventType === 'mouseover') {
				if (e.column.dataField == 'teachers') {
					if (e.value.length > 0) {
						let teacherText = '';
						e.value.forEach(val => {
							if (val.delFl == this.$_enums.common.stringUsedFlag.NO.value) {
								teacherText += `<li>${val.teacherName}[${val.teacherId}]</li>`;
							}
						});
						this.popover_caption = '강사';
						this.popover_description = `<ul class="teacher-list">${teacherText}</ul>`;
						this.defaultVisible = true;
					}
				}
			}
		},
		onSelectionChanged(e) {
			this.$refs.educationSubjectGrid.selectedRowsData = e.selectedRowsData;
			//const selectedRowsData = this.$refs.educationSubjectGrid.selectedRowsData;
		},
		getAttachFileUploadUrl() {
			return this.attachFileUrl.fileUpload;
		},
		/** @description : 교육자료 다운로드 */
		onDataDownload(data) {
			const fileGroupId = data.attacheFiles[0].groupId;
			const fileName = data.attacheFiles[0].fileName;

			const token = this.$store.getters.getAccessToken;

			const downloadUrl = `${this.attachFileUrl.fileDownload}/${fileGroupId}/${fileName}`;

			const requestOptions = {
				method: 'GET',
				headers: new Headers({
					Authorization: 'Bearer ' + token,
				}),
			};

			fetch(downloadUrl, requestOptions)
				.then(response => {
					if (!response.ok) {
						this.$_Toast('다운로드 오류');
						throw new Error('response error');
					}
					return response.blob();
				})
				.then(blob => {
					const url = window.URL.createObjectURL(blob);

					const a = document.createElement('a');
					a.href = url;
					a.download = data.attacheFiles[0].originalFileFullName; // 다운로드될 파일의 이름 설정
					document.body.appendChild(a);
					a.click();
					document.body.removeChild(a);
					window.URL.revokeObjectURL(url);
				})
				.catch(error => {
					console.error('file download error:', error);
					this.$_Toast('다운로드 오류');
				});
		},
		/** @description : 교육자료 삭제 */
		async onDataRemove(data) {
			return await this.attacheFileRemove({
				fileGroupId: data.attacheFiles[0].groupId,
				fileName: data.attacheFiles[0].fileName,
			});
		},
		/** @description : 첨부파일 삭제 */
		async attacheFileRemove(data) {
			const fileGroupId = data.fileGroupId;
			//const fileName = data.fileName;

			const token = this.$store.getters.getAccessToken;

			const deleteFileUrl = `${this.attachFileUrl.fileDeleteAll}/${fileGroupId}`;

			const requestOptions = {
				method: 'DELETE',
				headers: new Headers({
					Authorization: 'Bearer ' + token,
				}),
			};

			try {
				const response = await fetch(deleteFileUrl, requestOptions);

				if (!response.ok) {
					throw new Error('response error');
				}

				//const data = await response.json();

				return true;
			} catch (error) {
				console.error('remove file error:', error);
				return false;
			}
		},
		/** @description : 교육강의 삭제버튼 클릭 */
		async onDeleteData() {
			const selectedRowsData = this.$refs.educationSubjectGrid.selectedRowsData;

			const existUseFl = selectedRowsData.findIndex(element => element.useFl === this.$_enums.common.stringUsedFlag.YES.value);

			if (existUseFl > -1) {
				this.$_Msg(`사용중인 교육이 있습니다. '${selectedRowsData[existUseFl].eduNm}' 강의를 확인하세요.`);
				return;
			}

			const sendData = selectedRowsData.map(x => x.subjId);
			const removeFileData = selectedRowsData.map(x => x.fileGroupId).filter(groupId => groupId !== undefined && groupId !== null);

			if (!selectedRowsData?.length) {
				this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
				return;
			}

			if (!(await this.$_Confirm(this.$_msgContents('CMN_CFM_DELETE_SELECTED'), { title: `<h2>알림</h2>` }))) {
				return;
			}

			const payload = {
				actionname: 'EWM_EDUCATION_SUBJECT_DELETE',
				data: sendData,
				loading: true,
			};

			const res = await this.CALL_EWM_API(payload);

			if (isSuccess(res)) {
				await removeFileData.forEach(fileGroupId => {
					this.attacheFileRemove({ fileGroupId: fileGroupId });
				});

				this.$_Toast(this.$_msgContents('CMN_SUC_DELETE'));
				//this.$refs.educationSubjectGrid.getGridInstance.refresh();
				this.selectDataList();
			} else {
				this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
			}
		},
		/** @description : 교육강의 저장 */
		async onSave(e) {
			this.$log.debug('e.changes data', e.changes);
			e.cancel = true;

			this.$log.debug('e.changes data', e.changes);

			if (!(await this.$_Confirm(this.$_msgContents('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' }), { title: `<h2>알림</h2>` }))) {
				return;
			}

			if (!e.changes.length) {
				return;
			}

			let data = [];

			if (e.changes && e.changes.length > 0) {
				e.changes.forEach(d => {
					this.$log.debug('e.changes data', d);
					this.$log.debug('e.changes data', d.data);
					if (d.type === 'update') {
						//d.data.editId = this.$store.getters.getLoginId; //수정자

						let rowIndex = e.component.getRowIndexByKey(d.key);
						let row = e.component.getVisibleRows()[rowIndex];
						let updateData = row.data;

						/* let updateData = { ...d.data };
						updateData[this.dataGrid.keyExpr] = d.key; */

						data.push(updateData);
					} else if (d.type === 'insert') {
						if (Object.keys(d.data).length !== 0) {
							//d.data.regId = this.store.getters.getLoginId; //등록자
							let updateData = { ...d.data };
							updateData['subjId'] = null;

							data.push(updateData);
						}
					}
				});
			}

			const payload = {
				actionname: 'EWM_EDUCATION_SUBJECT_SAVE',
				data: { data: data },
			};

			const res = await this.CALL_EWM_API(payload);

			if (isSuccess(res)) {
				this.$_Toast(this.$_msgContents('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
				/* this.$refs.educationSubjectGrid.getGridInstance.refresh();
				e.component.cancelEditData(); */
				this.selectDataList();
			} else {
				this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
			}
		},
		/** @description : 데이터 추가 시 기본 값 적용하기 위함 */
		onInitNewRow(e) {
			if (this.$refs.educationSubjectGrid.isClone) {
				//e.data = Object.assign({}, this.$refs.educationSubjectGrid.selectedCopyData);
				e.data = this.$_commonlib.cloneObj(this.$refs.educationSubjectGrid.selectedCopyData);

				delete e.data.subjId; // remove PK propertye

				e.data.teachers.forEach(el => {
					delete el.id;
				});
				e.data.eduNm += ' 복사생성';
				e.data.viewFl = this.$_enums.common.stringUsedFlag.YES.value;
				e.data.useFl = this.$_enums.common.stringUsedFlag.NO.value;
				e.data.delFl = this.$_enums.common.stringUsedFlag.NO.value;

				const fileGroupId = e.data.fileGroupId;

				if (fileGroupId) {
					/* const uploadedFiles = await this.attacheFileCopy(fileGroupId);

					if (uploadedFiles.length && uploadedFiles.length > 0) {
						e.data.fileGroupId = uploadedFiles[0].fileGroupId;
						e.data.attacheFiles[0].groupId = uploadedFiles[0].fileGroupId;
						e.data.attacheFiles[0].fileName = uploadedFiles[0].fileName;
						e.data.attacheFiles[0].extension = uploadedFiles[0].extension;
						e.data.attacheFiles[0].fileFullName = uploadedFiles[0].fileFullName;
						e.data.attacheFiles[0].originalName = uploadedFiles[0].originalFileFullName;
						e.data.attacheFiles[0].fileSize = uploadedFiles[0].fileSize;
						e.data.attacheFiles[0].sequence = uploadedFiles[0].fileSequence;
					} */

					e.promise = this.attacheFileCopy(fileGroupId)
						.then(uploadedFiles => {
							e.data.fileGroupId = uploadedFiles[0].fileGroupId;
							e.data.attacheFiles[0].groupId = uploadedFiles[0].fileGroupId;
							e.data.attacheFiles[0].fileName = uploadedFiles[0].fileName;
							e.data.attacheFiles[0].extension = uploadedFiles[0].extension;
							e.data.attacheFiles[0].fileFullName = uploadedFiles[0].fileFullName;
							e.data.attacheFiles[0].originalName = uploadedFiles[0].originalFileFullName;
							e.data.attacheFiles[0].fileSize = uploadedFiles[0].fileSize;
							e.data.attacheFiles[0].sequence = uploadedFiles[0].fileSequence;
						})
						.catch(error => {
							e.data.fileGroupId = null;
							e.data.attacheFiles = [];

							console.error('copy file error:', error);
						});
				}

				this.$refs.educationSubjectGrid.isClone = false;
			} else {
				e.data.eduNm = '';
				e.data.divisionCd = '';
				e.data.typeCd = '';
				e.data.eduTime = 0;
				e.data.teachers = [];
				e.data.viewFl = this.$_enums.common.stringUsedFlag.YES.value;
				e.data.useFl = this.$_enums.common.stringUsedFlag.NO.value;
				e.data.delFl = this.$_enums.common.stringUsedFlag.NO.value;
				e.data.fileGroupId = null;
				e.data.attacheFiles = [];
			}
		},
		/** @description : 첨부파일 복사 */
		async attacheFileCopy(fileGroupId) {
			const token = this.$store.getters.getAccessToken;

			const fileCopyUrl = `${this.attachFileUrl.fileCopy}/${fileGroupId}`;

			const requestOptions = {
				method: 'GET',
				headers: new Headers({
					Authorization: 'Bearer ' + token,
				}),
			};

			return fetch(fileCopyUrl, requestOptions)
				.then(response => {
					if (!response.ok) {
						throw new Error('response error');
					}
					return response.json();
				})
				.then(data => {
					return data.data;
				})
				.catch(error => {
					console.error('remove file error:', error);
					return false;
				});

			/* try {
				const response = await fetch(fileCopyUrl, requestOptions);

				if (!response.ok) {
					throw new Error('response error');
				}

				const data = await response.json();
				console.log('data', data);

				return data.data;

			} catch(error) {
				console.error('remove file error:', error);
				return false;
			} */
		},
		/** @description : 강사 추가 */
		onAddTeachers(options) {
			//this.$refs.educationSubjectGrid.selectedRowKeys = [ data.id ];

			this.$refs.educationSubjectGrid.getGridInstance.selectRows([options.data.subjId], false);
			this.selectedRowKey = options.data.subjId;
			this.selectedRowIndex = options.rowIndex;
			//this.selectedRowIndex = this.$refs.educationSubjectGrid.getGridInstance.getRowIndexByKey(options.data.subjId);

			this.modalAddAgent.selectedIdList = options.data.teachers
				.filter(teacher => teacher.delFl != this.$_enums.common.stringUsedFlag.YES.value)
				.map(teacher => teacher.teacherId);
			vm.onModalAddAgentOpen();
		},
		/** @description : 라이프사이클 computed에서 this data를 변경하기 위한 메서드 */
		setCustomTypes(key, value) {
			this.searchType.customTypes[key] = value;
		},
		onChangeSearchDay() {
			const regDt = this.searchType.customTypes.frRegDt + '000000' + '~' + this.searchType.customTypes.toRegDt + '235959';
			this.searchType.paramsData = { ...this.searchType.paramsData, regDt };

			this.$_setSearchHistsCustomType(this.searchType.paramsData);
		},
		/** @description : 데이터 검색 메서드 */
		async selectDataList222(sort = '-sbjId') {
			//return;
			this.dataGrid.dataSource = new CustomStore({
				key: 'subjId',
				async load(loadOptions) {
					/*let params = vm.$_getDxDataGridParam(loadOptions);

					if (!params.sort) {
						params.sort = sort;
					}*/
					vm.$refs.educationSubjectGrid.getGridInstance.deselectAll();

					vm.searchType.paramsData['frRegDt'] = vm.searchType.customTypes.frRegDt;
					vm.searchType.paramsData['toRegDt'] = vm.searchType.customTypes.toRegDt;

					//params = { ...params, ...vm.searchType.paramsData };

					const payload = {
						actionname: 'EWM_EDUCATION_SUBJECT_LIST',
						/* data: {
							params: params,
						}, */
						data: vm.searchType.paramsData,
						loading: false,
					};

					const res = await vm.CALL_EWM_API(payload);
					if (isSuccess(res)) {
						const rtnData = {
							data: res.data.data,
							totalCount: res.data.header.totalCount,
						};

						vm.$refs.educationSubjectGrid.totalCount = rtnData.totalCount;
						return rtnData;
					}
				},
				insert(values) {
					//return false;
				},
				update(key, values) {
					//return false;
				},
				totalCount: opts => {
					//데이터 없는 경우 totalCount 에러 발생하여 추가
					return new Promise((resolve, reject) => {
						resolve(0);
					});
				},
				/* remove(key) {
					return false;
				}, */
			});
		},
		async selectDataList() {
			if (!vm.validationGroup.validate().isValid) {
				return;
			}

			vm.$refs.educationSubjectGrid.getGridInstance.deselectAll();

			vm.searchType.paramsData['frRegDt'] = vm.searchType.customTypes.frRegDt;
			vm.searchType.paramsData['toRegDt'] = vm.searchType.customTypes.toRegDt;

			const payload = {
				actionname: 'EWM_EDUCATION_SUBJECT_LIST',
				/* data: {
					params: params,
				}, */
				data: vm.searchType.paramsData,
				loading: false,
			};

			//vm.dataGrid.dataSource = [];

			const res = await vm.CALL_EWM_API(payload);
			if (isSuccess(res)) {
				const rtnData = {
					data: res.data.data,
					totalCount: res.data.header.totalCount,
				};

				vm.$refs.educationSubjectGrid.totalCount = rtnData.totalCount;

				vm.dataGrid.dataSource = rtnData.data;

				vm.$refs.educationSubjectGrid.getGridInstance.cancelEditData();
			}
		},
		/** @description : 날짜 형식 변환 메서드 */
		formatDt(rowData) {
			if (rowData?.regDt) {
				return this.$_commonlib.formatDate(rowData.regDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
			}
		},
		/** @description : 코드 로드 */
		async initCodeMap() {
			this.codeMap = await this.$_getCodeMapList('root_ewm_edu_education_type');
		},
		/** @description : 라이프사이클 created시 호출되는 메서드 */
		createdData() {
			vm = this;

			this.initCodeMap().then(() => {
				//this.dataGridLoopup.divisionCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_edu_education_process_division'], 2);
				this.dataGridLoopup.typeCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_edu_education_type'], 2);
				//this.$refs.educationSubjectGrid.getGridInstance.columnOption('divisionCd', 'lookup', this.dataGridLoopup.divisionCd);
				this.$refs.educationSubjectGrid.getGridInstance.columnOption('typeCd', 'lookup', this.dataGridLoopup.typeCd);
			});
		},
		/** @description : 라이프사이클 mounted시 호출되는 메서드 */
		mountData() {
			//this.searchType.paramsData = null;
			//this.onChangeSearchDay();
			//this.$refs.educationSubjectGrid.getGridInstance.columnResizingMode = 'widget';
			//this.$refs.educationSubjectGrid.getGridInstance.on('cellHoverChanged', this.onCellHoverChanged);

			const apiFileUpload = this.$store.getters.getRoutingInfos.find(d => d.actionNm === 'COMMON_ATTACHED_FILE_UPLOAD');
			const apiFileDownload = this.$store.getters.getRoutingInfos.find(d => d.actionNm === 'COMMON_ATTACHED_FILE_DOWNLOAD');
			const apiFileDelete = this.$store.getters.getRoutingInfos.find(d => d.actionNm === 'COMMON_ATTACHED_FILE_DELETE');
			const apiFileDeleteAll = this.$store.getters.getRoutingInfos.find(d => d.actionNm === 'COMMON_ATTACHED_FILE_GROUP_DELETE');
			const apiFileCopy = this.$store.getters.getRoutingInfos.find(d => d.actionNm === 'COMMON_ATTACHED_FILE_COPY');

			/* this.attachFileUrl.fileUpload = apiFileUpload.apiserver.serverurl + apiFileUpload.url;
			this.attachFileUrl.fileDownload = apiFileDownload.apiserver.serverurl + apiFileDownload.url;
			this.attachFileUrl.fileDelete = apiFileDelete.apiserver.serverurl + apiFileDelete.url;
			this.attachFileUrl.fileDeleteAll = apiFileDeleteAll.apiserver.serverurl + apiFileDeleteAll.url;
			this.attachFileUrl.fileCopy = apiFileCopy.apiserver.serverurl + apiFileCopy.url; */

			this.attachFileUrl.fileUpload = apiFileUpload.host + apiFileUpload.path;
			this.attachFileUrl.fileDownload = apiFileDownload.host + apiFileDownload.path;
			this.attachFileUrl.fileDelete = apiFileDelete.host + apiFileDelete.path;
			this.attachFileUrl.fileDeleteAll = apiFileDeleteAll.host + apiFileDeleteAll.path;
			this.attachFileUrl.fileCopy = apiFileCopy.host + apiFileCopy.path;

			//this.$refs.educationSubjectGrid.getGridInstance.on('editingStart', this.onEditingStart);

			this.selectDataList();
		},
	},
	created() {
		this.createdData();
	},
	mounted() {
		this.mountData();
	},
};
</script>

<style lang="scss" scoped>
/* .page_search_box .inner div {
	display: inline-block;
} */
.page_search_box .inner > div:not(.box-btn-search) {
	vertical-align: middle;
	margin-right: 10px;
}
.page_search_box .spec-type-box {
	display: inline-block;
}
.page_search_box .inner .box-btn-search {
	margin-right: 15px;
}
.page_search_box #searchTooltip {
	margin-right: 5px;
	font-size: 22px;
	color: #808080;
}

::v-deep {
	.teacher-list li {
		margin: 0.7em 0.5em;
	}
}
</style>
<style>
.ewm .dx-data-row .ewm-education-subject-cell-teacher {
	color: blue !important;
	text-decoration: underline;
	cursor: pointer;
}

.ewm .dx-command-select {
	padding-left: 3px !important;
}

.ewm .dx-fileuploader-input-container {
	width: 0px;
}

.ewm .dx-fileuploader-input-wrapper .dx-button {
	float: none !important;
}

/* 
.ewm .dx-datagrid .dx-datagrid-table .dx-row.dx-header-row > td {
	border-top: 1px solid #999;
}
.ewm .dx-datagrid .dx-datagrid-table .dx-row.dx-header-row > td:not(:last-child),
.ewm .dx-datagrid .dx-datagrid-table .dx-row.dx-datagrid-filter-row > td:not(:last-child) {
	border-right: 1px solid #e0e0e0;
} */
</style>
